import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Other/seo';

const Courses = () => (
	<Layout>
		<div className='center-container'>
			<div className='row middle-xs'>
				<div className='col-xs-12 text-center'>
					<SEO title='Florin Pop - Courses' />
					<h1>
						Courses <small>by Wes Bos</small>
					</h1>

					<ul className='courses-list'>
						<h3>JavaScript</h3>
						<li>
							<a
								href='http://bit.ly/WBJSBFP'
								target='_blank'
								rel='noopener noreferrer'>
								JavaScript for Beginners
							</a>
						</li>
						<li>
							<a
								href='http://bit.ly/WBES6FP'
								target='_blank'
								rel='noopener noreferrer'>
								ES6
							</a>
						</li>
						<li>
							<a
								href='http://bit.ly/WBJS30FP'
								target='_blank'
								rel='noopener noreferrer'>
								JavaScript 30
							</a>
						</li>
						<h3>React & Redux</h3>
						<li>
							<a
								href='http://bit.ly/WBReactFP'
								target='_blank'
								rel='noopener noreferrer'>
								React For Beginners
							</a>
						</li>
						<li>
							<a
								href='http://bit.ly/WBAReactFP'
								target='_blank'
								rel='noopener noreferrer'>
								Advanced React
							</a>
						</li>
						<li>
							<a
								href='http://bit.ly/WBReduxFP'
								target='_blank'
								rel='noopener noreferrer'>
								Redux
							</a>
						</li>
						<h3>NodeJS</h3>
						<li>
							<a
								href='http://bit.ly/WBNodeFP'
								target='_blank'
								rel='noopener noreferrer'>
								NodeJS
							</a>
						</li>
						<h3>CSS Grid and Flexbox</h3>
						<li>
							<a
								href='http://bit.ly/WBGridFP'
								target='_blank'
								rel='noopener noreferrer'>
								CSS Grid
							</a>
						</li>
						<li>
							<a
								href='http://bit.ly/WBFlexboxFP'
								target='_blank'
								rel='noopener noreferrer'>
								CSS Flexbox
							</a>
						</li>
					</ul>

					<i style={{ lineHeight: '25px', opacity: 0.9 }}>
						<strong>Disclaimer! </strong>
						Keep in mind that these are affiliate links and if you decide to
						purchase one or multiple courses, I'm going to get a commission, at
						no extra cost for you. The money earned through these affiliate
						links is just another way for me to earn some extra income so that I
						can continue doing what I love, which is helping people learn
						programming.
					</i>
				</div>
			</div>
		</div>
	</Layout>
);

export default Courses;
